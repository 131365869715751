import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { FOOTER_DATA } from '../../data/index';
import React from 'react';
import { FooterArea, CopyrightContainer } from './footer.style';
import { Image } from 'antd';

const Footer = () => {
  const { logo, menu, social, strideLogo } = FOOTER_DATA;
  return (
    <FooterArea>
      <Container>
        <Box className="logoBox">
          <a className="logo">
          <Image src={ logo } preview={false} width={150} />
          </a>
        </Box>
        <Box className="social">
          <Text as="span" content={ <>236, Sarai Pipal Thala, Opp Metro Pillar No 95, Adarsh Nagar, Delhi – 110033, India</> } />
          <Text as="span" content={ <><br />+91-9953671716</> } />
          <Text as="span" content={ <><br />admissions.sbit@gmail.com</> } />
        </Box>
        <Box className="logoBox">
          <a className="logo">
            <img src={ strideLogo } alt="logo footer" />
          </a>
        </Box>
      </Container>
      <CopyrightContainer>
        <Text as="span" content={ <><br />Copyright: © Copyright 2024 SBIT || All Rights Reserved.</> } />
      </CopyrightContainer>
    </FooterArea>
  );
};

export default Footer;
